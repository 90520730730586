
import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const ReplacingSixers = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="3 Sixers I Could Replace" />
        <meta name="twitter:image" content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel='stylesheet' href='../styles/global.css' />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>3 Sixers I Could Replace</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. May 16, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
              Yeah we seeded first but throw Big Shoya aka the Little Aristotle in the mix and we guaranteed the whole meal come Finals.
          </p>
          <p className="highlight">I. Shake Milton (6'5" 205 lbs.)</p>
          <p>
            Ard LMAO I know I'm coming in hot here but just hear me out. Milkshake over here look just like some bul from around my way growing up who I used to fry at the park so based off that I just feel like I could make at least equal if not greater contribution than somebody with this particular mitt/phenotype lmaoooo.
          </p>
          <p className="highlight">II. Rayjon Tucker (6'3" 209 lbs.)</p>
          <p>
              I'm a lil bit more serious about this one lol cuz naw yungbol don't get no tick c'mon now I know I could at least draw some charges to justify this swap put me in coach. Speaking of which: 
          </p>
          <p className="highlight">III. Glen "Doc" Rivers (6'4" 210 lbs.)</p>
          <p>
              I didn't specify pl.ayers now did I LOL. I, too, am capable of yelling 'HEDGE! HEDGE! HEDGE!' from the sideline and then folding my arms authoritatively. Plus I got good handwriting for the dry-erase situation what more do we need fr. I got a couple trick plays from my JV days I could pull out too I'm sure that would go over just fine.
          </p>
          <p>
              I'm not even throwing salt I just had to put this out there to throw the reverse-jinx on the whole situation lmao we kissing the Larry O'Brien for sure now.
          </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>

  );
};
export default ReplacingSixers;